@font-face {
  font-family: "Sofia";
  src: local("Sofia"), url(../font/SofiaPro-Regular.otf) format("opentype");
  ascent-override: 105%;
}

body {
  margin: 0;
  font-family: Sofia !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size-adjust: 0.5;
}

#root {
  height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
